import { render, staticRenderFns } from "./TheDefaultFooter.vue?vue&type=template&id=66ede540&scoped=true"
import script from "./TheDefaultFooter.vue?vue&type=script&lang=js"
export * from "./TheDefaultFooter.vue?vue&type=script&lang=js"
import style0 from "./TheDefaultFooter.vue?vue&type=style&index=0&id=66ede540&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ede540",
  null
  
)

export default component.exports